import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import ModalProducts from '../components/ModalProducts';

import AniBlockdata from '../components/Animations/AniBlockdata';
import AniTransaction from '../components/Animations/AniTransaction';
import AniWhiteRouting from '../components/Animations/AniWhiteRouting';
import AniNetworkBlue from '../components/Animations/AniNetworkBlue';
import AniBlockchainBlue from '../components/Animations/AniBlockchainBlue';
import AniBlockBlue from '../components/Animations/AniBlockBlue';
import AniBlock from '../components/Animations/AniBlock';
import AniWhitePhonePos from '../components/Animations/AniWhitePhonePos';
import AniWhitePhone from '../components/Animations/AniWhitePhone';
import AniWhiteGlobe from '../components/Animations/AniWhiteGlobe';
import AniRouting from '../components/Animations/AniRouting';

import undraw_wind_turbine_x2k4 from '../img/undraw_wind_turbine_x2k4.svg';
import undraw_security_o890 from '../img/undraw_security_o890.svg';
import undraw_server_status_5pbv from '../img/undraw_server_status_5pbv.svg';
import undraw_building_blocks_n0nc from '../img/undraw_building_blocks_n0nc.svg';
import undraw_Data_points_ubvs from '../img/undraw_Data_points_ubvs.svg';
import undraw_world_9iqb from '../img/undraw_world_9iqb.svg';
import undraw_operating_system_4lr6 from '../img/undraw_operating_system_4lr6.svg';

const Platform = () => ( <Layout>
  <div className="skipScroll">
    <Helmet
      title={'Temporal Blockchain | temtum Consensus Algorithm |  temtum Network'}
      meta={[
        {
          name: 'description',
          content: 'The Temporal Blockchain provides the foundations for the exceptional capabilities of the temtum cryptocurrency.'
        }
      ]}
    />
    <div className="hd-space skipScroll"/>
    <div className="page skipScroll">
      <section className="skipScroll bg-blue-3 pb-0" id="platform">
        <video id="video-background" loop="loop" autoPlay="autoPlay" playsInline="playsinline" muted="muted">
          <source src={require('../img/video/temtum.mp4')} type="video/mp4"/>
        </video>
        <div className="darkestX blueblue video-overlay"/>
        <div className="darkerX video-overlayX"/>
        <div className="wow3 video-overlay"/>
        <div className="container mb-5 pb-5 text-white ">
          <div className="row">
            <div className="col-12 col-md-10 col-lg-7">
              <h6 className="display-upper-1X line red text-greyX mb-4" data-aos="fade-up" data-aos-duration="500" data-aos-delay="200">
                Introducing Temporal
              </h6>
              <h2 className="display-1 pb-3 headlineX mb-4X" data-aos="fade-up" data-aos-duration="500" data-aos-delay="400">
                <strong>temtum</strong> (TEM) Powered by <strong>Temporal Blockchain</strong>
              </h2>
              <a href="#why" className="btn btn-outline-primary scroll mb-5" data-aos="fade-up" data-aos-duration="500" data-aos-delay="600">
                Why Temporal
              </a>
            </div>
            <div className="col-12 col-md-10 col-lg-5">
              <p className="lead text-mutedX mt-5 pt-2" data-aos="fade-left" data-aos-duration="300" data-aos-delay="800">
                We have not only seen this positive future – we have designed the technology, built it, tested it, and mapped out how we will implement it in the real world. The future is coming.
              </p>
            </div>
          </div>
        </div>
        <div className="bg-trans-3 text-dark mt-5 pt-0 pb-5 text-center rel ">
          <div className="">
            <div className="container">
              <div className="row dark-cards" data-aos="fade-in" data-aos-duration="800">
                <div className="col col-12 col-sm-12 col-md-12  mx-auto mtm-5">
                  <div className="row">
                    <div className="col col-12 col-sm-12 col-md-3 mb-3" data-aos="fade-up" data-aos-duration="600">
                      <div className="card card-body">
                        {/* <img src={iconwindmill} className="" height="64px" width="64px" /> */}
                        <AniBlockdata/>
                        <h4 className="card-title">Temporal</h4>
                        {/* <p className="card-text">A unique consensus algorithm that does not require high end computing hardware to confirm transactions makes Temporal a greener blockchain solution.</p> */}
                      </div>
                    </div>
                    <div className="col col-12 col-sm-12 col-md-3 mb-3" data-aos="fade-up" data-aos-duration="800">
                      <div className="card card-body">
                        {/* <img src={iconblockchain} className="" height="64px" width="64px" /> */}
                        <AniTransaction/>
                        <h4 className="card-title">Consensus</h4>
                        {/* <p className="card-text">Data is not required by nodes to be stored locally forever on machines, making Temporal highly efficient and extremely lightweight.</p> */}
                      </div>
                    </div>
                    <div className="col col-12 col-sm-12 col-md-3 mb-3" data-aos="fade-up" data-aos-duration="1000">
                      <div className="card card-body">
                        {/* <img src={iconblock} className="" height="64px" width="64px" /> */}
                        <AniRouting/>
                        <h4 className="card-title">Routing</h4>
                        {/* <p className="card-text">Zero embedded transaction costs on the network, making temtum a powerful cryptocurrency for high volume trades and transactions.</p> */}
                      </div>
                    </div>
                    <div className="col col-12 col-sm-12 col-md-3 mb-3" data-aos="fade-up" data-aos-duration="1200">
                      <div className="card card-body">
                        {/* <img src={iconbeacon} className="" height="64px" width="64px" /> */}
                        <AniBlock/>
                        <h4 className="card-title">Randomness</h4>
                        {/* <p className="card-text">Temporal operates a next generation peer-to-peer reputation blockchain, making a 51% attack almost impossible to achieve.</p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white rel">
          <div className="container">
            <div className="row" data-aos="fade-in" data-aos-duration="800">
              <div className="col col-12 col-sm-12 col-md-12  mx-auto mt-3 text-center ">
                <a href="#why" className="btn btn-primary scroll mtm-5">
                  Learn about temtum <strong>(TEM)</strong>, powered by Temporal Blockchain
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="" className="bg-white skipScroll ">
        <div className="container mt-3 mb-4">
          <div className="row">
            <div className="col-lg-7 ">
              <div className="">
                <div className="">
                  <div className="boxedblueXX">
                    <h6 className=" line red text-grey mb-4">
                      Introducing Temporal
                    </h6>
                    <h3 className="display-3 font-weight-bold mb-4 text-redX">
                      <span data-aos="fade-up" data-aos-duration="200" data-aos-delay="400">
                        Speed.
                      </span> <span data-aos="fade-up" data-aos-duration="200" data-aos-delay="800">
                        Scalability.
                      </span>   <span data-aos="fade-up" data-aos-duration="200" data-aos-delay="1200">
                        Low Resource.
                      </span> <span data-aos="fade-up" data-aos-duration="200" data-aos-delay="1600">
                        Security.
                      </span>
                    </h3>
                    <p className="lead mb-4 mr-3">
                      The temtum network achieves truly decentralised scalability, near instant transaction speeds and unprecedented transaction throughput, using low resources and future-proof cryptographic security.
                    </p>

                    <a href="#blockchain" className="btn btn-outline-primary scroll">
                      About temtum
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              {/* <img src={iconconnect} className="oppimg" /> */}
              <img src={undraw_wind_turbine_x2k4} className="oppimg" alt=""/>
            </div>
          </div>
        </div>
      </section>
      <section id="blockchain" className="bg-blue-3 text-white skipScroll">
        <div className="container my-5">
          <div className="row">
            <div className="col-lg-5">
              <img src={undraw_Data_points_ubvs} className="oppimg" alt=""/>
            </div>
            <div className="col-lg-7">
              <div className="">
                <div className="">
                  <div className="boxedblueXX">
                    <h6 className="display-upper-1 headline red text-grey pb-3 mb-4">
                      5 Years in Development
                    </h6>
                    <h1 className="display-3 mb-4">
                      Temporal provides the fundamental elements of our technology and blockchain network.
                    </h1>
                    <p>
                      Our improved network routing, removal of the block size limits, and a system architecture that ensures a single, randomly selected node confirms all transactions for 60 second makes the only limitation to transaction throughput the hardware and bandwidth of network participants.
                    </p>
                    <p>
                      This is shown by the fact that we have demonstrated simulated transaction throughput speeds of 120,000 transactions per second in a laboratory environment.
                    </p>
                    <a href="#products" className="btn btn-outline-secondary scroll">
                      Our Products
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="text-dark skipScroll" id="products">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 mx-auto text-center">
              <h4 className="display-4 mb-3">Use temtum</h4>
              <h3 className="display-3 font-weight-bolder">
                Available Products
              </h3>
              <p className="mb-4 pb-4">
                The Temporal network, with its unique and stable method for generating randomness, via the open-source NIST Beacon, becomes highly secure and impossible to predict, a world first and most secure solution of any existing currency.
              </p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row mb-0">
            <div className="col-lg-6 mx-auto mb-4">
              <div className="card box border bg-platform-mobile">
                <h3 className="display-4 font-weight-bolder">
                  Mobile Applications
                </h3>
                <p className="mb-4">Wallets on both Android and Apple.</p>
                <div className="btn btn-outline-dark btn-sm" data-toggle="modal" data-target="#Mobile">
                  View <i className="fas fa-long-arrow-alt-right ml-2"/>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mx-auto mb-4">
              <div className="card box border bg-platform-mobile">
                <h3 className="display-4 font-weight-bolder">
                  Keyboard Payments
                </h3>
                <p className="mb-4">
                  Send and receive TEM via apps like Whatsapp and Telegram.
                </p>
                <div className="btn btn-outline-dark btn-sm" data-toggle="modal" data-target="#Keyboard">
                  View <i className="fas fa-long-arrow-alt-right ml-2"/>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-lg-6 mx-auto mb-4">
              <div className="card box border bg-platform-mobile">
                <h3 className="display-4 font-weight-bolder">Web Wallet</h3>
                <p className="mb-4">
                  Create an account and manage your temtum funds.
                </p>
                <div className="btn btn-outline-dark btn-sm" data-toggle="modal" data-target="#Wallet">
                  View <i className="fas fa-long-arrow-alt-right ml-2"/>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mx-auto mb-4">
              <div className="card box border bg-platform-mobile">
                <h3 className="display-4 font-weight-bolder">
                  SMS Transfers
                </h3>
                <p className="mb-4">
                  Search by block hash, index or transaction hash.
                </p>
                <div className="btn btn-outline-dark btn-sm" data-toggle="modal" data-target="#Sms">
                  View <i className="fas fa-long-arrow-alt-right ml-2"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="text-dark bg-light-grey skipScroll" id="why">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 mx-auto text-center">
              <h4 className="display-4 mb-3">Temporal Blockchain</h4>
              <h3 className="display-3 font-weight-bolder">
                World Class Innovation in Payment Systems
              </h3>
              <p className=" mb-4 pb-4">
                Rather than building from the top down, we are harnessing the genuinely democratic and decentralised ethos of blockchain and building from the bottom up, by directly addressing the problem of almost two billion people in the world without access to banking services. temtum can help to bring useful financial services to this enormous global segment. {/*The Temporal network, with its unique and stable method for
                  generating randomness, via the open-source NIST Beacon,
                  becomes highly secure and impossible to predict, a world first
                  and most secure solution of any existing currency.*/
              }
              </p>
            </div>
          </div>
        </div>
        <div className="bg-light-greyX  text-dark mt-5 pb-5 rel dark-cardsX plain-cards ">
          <div className="">
            <div className="container">
              <div className="row" data-aos="fade-in" data-aos-duration="800">
                <div className="col col-12 col-sm-10 col-md-10  mx-auto mtm-5">
                  <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                    <ol className="carousel-indicators">
                      <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"/>
                      <li data-target="#carouselExampleIndicators" data-slide-to="1"/>
                    </ol>
                    <div className="carousel-inner">
                      <div className="carousel-item active  card-body">
                        <div className="row">
                          <div className="col col-12 col-sm-12 col-md-6 mb-3 text-center" data-aos="fade-up" data-aos-duration="600">
                            <div className="card card-body py-5">
                              <AniWhitePhonePos/>
                              <h4 className="card-title display-4 font-weight-bold">
                                Mobile Payments POS
                              </h4>
                              <p className="card-text">
                                We have established solutions for point of sale (POS) mobile payments so that there will be a seamless process for purchases. For the user they will not differ in experience from regular currency payments.
                              </p>
                            </div>
                          </div>
                          <div className="col col-12 col-sm-12 col-md-6 mb-3 text-center" data-aos="fade-up" data-aos-duration="800">
                            <div className="card card-body py-5">
                              <AniWhitePhone/>
                              <h4 className="card-title display-4 font-weight-bold">
                                Text Transfers
                              </h4>
                              <p className="card-text">
                                We are working on a laboratory-proven concept to achieve secure authentication between the mobile device and the network in order for users to use a simple SMS message to send temtum from wallet to wallet.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="carousel-item  card-body X">
                        <div className="row">
                          <div className="col col-12 col-sm-12 col-md-6 mb-3 text-center" data-aos="fade-up" data-aos-duration="1000">
                            <div className="card card-body py-5">
                              <AniWhiteRouting/>
                              <h4 className="card-title display-4 font-weight-bold">
                                Network Gateway
                              </h4>
                              <p className="card-text">
                                The temtum Network Gateway extracts information from transactions and, according the onboarding module information, decides whether the transaction goes to fiat transactions process (SWIFT, intrabank, or credit or debit card) or crypto transactions process.
                              </p>
                            </div>
                          </div>
                          <div className="col col-12 col-sm-12 col-md-6 mb-3 text-center" data-aos="fade-up" data-aos-duration="1200">
                            <div className="card card-body py-5">
                              <AniWhiteGlobe/>
                              <h4 className="card-title display-4 font-weight-bold">
                                Swift Integration
                              </h4>
                              <p className="card-text">
                                This component receives transactions from the temtum Network Gateway, validating them and processing the fiat transaction. It contains all the bank API connectors responsible for connecting the temtum Network to the private bank API and send the fiat transactions to the receiver account.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                      <span className="carousel-control-prev-icon" aria-hidden="true"/>
                      <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                      <span className="carousel-control-next-icon" aria-hidden="true"/>
                      <span className="sr-only">Next</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="section1" className="skipScroll bg1X bg-blue-3 text-white">
        <div className="wow3 video-overlay"/>
        <div className="container my-5">
          <div className="row">
            <div className="col-lg-6 ">
              <div className="">
                <div className="">
                  <div className="boxedblueXX">
                    <h6 className="display-upper-1 headline red text-grey pb-3 mb-4">
                      Resilience to an Attack
                    </h6>
                    <h1 className="display-3 mb-4 ">
                      temtum represents a paradigm shift from current blockchain networks.
                    </h1>
                    <p>
                      Proof-of-work blockchain networks rely on the decentralisation of hashing power to ensure network security – an attacker that is able to gain control over more than 51% of the resources of any given proof-of-work blockchain would be able to execute a successful double spend attack with a high probability of success. This is sometimes called a Sybil attack.
                    </p>
                    <p>
                      To eliminate the threat of a Sybil attack, the temtum reputation and monitoring system ensures that attackers cannot simply add more resources to the network over the course of one hour, as is possible with contemporary proof-of-work blockchain networks. An attacker would need to add additional resources to the temtum network and operate them in an honest manner over a period of 6-12 months before such an attack could be conducted.
                    </p>
                    <p className="font-weight-bold">
                      In order to execute a successful Sybil attack on the temtum network, an attacker would need to control over 50% of the authority nodes on the network, as well as determine the NIST Randomness Beacon value in advance.
                    </p>
                    <a href="#architecture" className="btn btn-outline-secondary scroll">
                      System Architecture
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <img src={undraw_operating_system_4lr6} className="oppimg mt-5" alt=""/>
            </div>
          </div>
        </div>
      </section>
      <section className=" choose-us skipScroll text-whiteX  bg-blue-2X bg-transX " id="core">
        <div className="platformbackXX"/>
        <div className="container">
          <div className="row">
            <div className="col-lg-9 mx-auto">
              <h6 className="display-upper-1 headline red pb-3 mb-4">
                Core Features
              </h6>
              <h1 className="display-2 font-weight-bolder pb-3">
                How temtum (TEM) works
              </h1>
              <p className="lead">
                The temtum network achieves future-proof cryptographic security, extremely fast transaction speeds, energy efficiency, unprecedented transaction throughput, and true decentralized scalability.
              </p>
            </div>
          </div>
        </div>
        <hr/>
        <div className="container pt-3 pb-3">
          <div className="row">
            <div className="col-lg-9 mx-auto text-center">
              <p className="pt-0 pb-0 mb-0 text-dark-blue">
                temtum is our token to be used as the method of exchange for goods and services using the Temporal Blockchain network.
              </p>
            </div>
          </div>
        </div>
        <hr/>
        <div className="container">
          <div className="row">
            <div className="col-lg-9 mx-auto">
              <div id="our-process" className="our-process ">
                <div className="section-content ">
                  <div className="process ">
                    <div className="our-process-image " data-aos="fade-in" data-aos-duration="1400">
                      <span className="">
                        <AniNetworkBlue/>
                      </span>
                    </div>
                    <div className="our-process-text ">
                      <h4 className="display-3 mb-4 font-weight-bold text-dark-blue">
                        NIST Beacon
                      </h4>
                      <p>
                        We heavily utilize the NIST Randomness Beacon in the temtum consensus algorithm and as a timestamp in block generation. The NIST Randomness Beacon removes the need for energy-intensive Proof of Work consensus and eliminates the possibility of a fork in the blockchain or a double spend.{' '}
                        {/* The Beacon delivers a superior source of randomness, leveraging a physically observable manifestation of the wave nature of quantum systems to generate fundamentally unpredictable results. */}
                        temtum is the first cryptographic protocol to commercially deploy a successful implementation of a random number generated by the NIST Randomness Beacon.{' '}
                      </p>
                    </div>
                  </div>
                  <div className="divider hidden-xs divider-right"/>
                  <div className="process ">
                    <div className="our-process-image left" data-aos="fade-in" data-aos-duration="1400">
                      <span className="">
                        <AniBlockchainBlue/>
                      </span>
                    </div>
                    <div className="our-process-text right">
                      <h4 className="display-3 mb-4 font-weight-bold text-dark-blue">
                        temtum Consensus Algorithm
                      </h4>
                      <p>
                        We define a small subset of nodes which function as semi-trusted authority nodes. The number of semi-trusted authority nodes, a list of which ships with the temtum software, scales with network load. The function of semi-trusted authority nodes within the temtum ecosystem can be compared to the presence of similar nodes in the Tor decentralised peer to peer privacy network, which uses “directory authorities.”
                      </p>
                    </div>
                  </div>
                  <div className="divider hidden-xs divider-left"/>
                  <div className="process ">
                    <div className="our-process-image " data-aos="fade-in" data-aos-duration="1400">
                      <span className="">
                        <AniBlockBlue/>
                      </span>
                    </div>
                    <div className="our-process-text ">
                      <h4 className="display-3 mb-4 font-weight-bold text-dark-blue">
                        Temporal Blockchain
                      </h4>
                      <p>
                        Temporal technology provides the temtum network with a "deletable" blockchain, distributing blockchain data storage across a range of specialized nodes in order to allow low-powered devices to participate in the temtum network.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="info" className="skipScroll bg1X bg-blue-3 text-white nopad">
        <div id="platform-wide" className="carousel slide" data-ride="carousel">
          <ul className="carousel-indicators">
            <li data-target="#platform-wide" data-slide-to="0" className="active"/>
            <li data-target="#platform-wide" data-slide-to="1"/>
            <li data-target="#platform-wide" data-slide-to="2"/>
            <li data-target="#platform-wide" data-slide-to="3"/>
          </ul>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <section id="architecture" className="bg-blue-3 text-white skipScroll ">
                <div className="container my-5">
                  <div className="row">
                    <div className="col-lg-5">
                      <img src={undraw_world_9iqb} className="oppimg" alt=""/>
                    </div>
                    <div className="col-lg-6 ">
                      <div className="">
                        <div className="">
                          <div className="boxedblueXX">
                            <h6 className="display-upper-1 headline red text-grey pb-3 mb-4">
                              Leader Nodes
                            </h6>
                            <h4 className="display-3 mb-4">
                              The temtum consensus algorithm selects trusted nodes at random to become a leader node
                            </h4>
                            <p>
                              Leader nodes are responsible for all blocks within the 60-second window in which they are block leader, eliminating the potential of a fork. If the leader node goes offline during the time in which it is responsible for block confirmation, all unconfirmed transactions will be rolled into the next leader node’s block.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div className="carousel-item">
              <section id="section3" className="skipScroll bg-blue-2 text-white bgX2">
                <div className="dark blueblue video-overlay"/>
                <div className="wow3 video-overlay"/>
                <div className="container my-5">
                  <div className="row">
                    <div className="col-lg-6 ">
                      <div className="">
                        <div className="">
                          <div className="boxedblueXX">
                            <h6 className="display-upper-1 headline red text-grey pb-3 mb-4">
                              Node Participation Document
                            </h6>
                            <h4 className="display-3 mb-4 ">
                              The NPD is critical in the process that results in the selection of the leader node
                            </h4>
                            <p>
                              As every node participating in the temtum network possesses a copy of the NPD, each node is able to select the leader without reference to or querying any other node or central authority. Each node must maintain a copy of the NPD and can be queried for it at any time in order to reduce the load on authority nodes.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <img src={undraw_building_blocks_n0nc} className="oppimg mt-5" alt=""/>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div className="carousel-item">
              <section id="section4" className="bg-blue-3 text-white skipScroll bg-glow ">
                <div className="container my-5">
                  <div className="row">
                    <div className="col-lg-5">
                      <img src={undraw_server_status_5pbv} className="oppimg mt-5" alt=""/>
                    </div>
                    <div className="col-lg-6 ">
                      <div className="">
                        <div className="">
                          <div className="boxedblueXX">
                            <h6 className="display-upper-1 headline red text-grey pb-3 mb-4">
                              System Architecture
                            </h6>
                            <h1 className="display-3 mb-4">
                              Improved Network Routing
                            </h1>
                            <p>
                              With our improved network routing, the removal of the block size limit, and a system architecture that ensures a single pre-defined node confirms all transactions for a single minute, the only limitation to transaction throughput is the hardware and network bandwidth of network participants.
                            </p>
                            <p>
                              Simulated transaction throughput speeds of 120,000 transactions per second have been demonstrated in a laboratory environment.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div className="carousel-item">
              <section id="section5" className="bg-blue-3 text-white skipScroll bg1X">
                <div className="dark blueblue video-overlay"/>
                <div className="wow3 video-overlay"/>
                <div className="container my-5">
                  <div className="row">
                    <div className="col-lg-6 ">
                      <div className="">
                        <div className="">
                          <div className="boxedblueXX">
                            <h6 className="display-upper-1 headline red text-grey pb-3 mb-4">
                              No limit block sizes
                            </h6>
                            <h1 className="display-3 mb-4">
                              Temporal has no block size limitation in place, unlike Bitcoin, and this means that there is no theoretical limit on how many transactions can be included in a block
                            </h1>
                            <p>
                              Bitcoin, for example, generates one block every 10 minutes. Through extensive research, we have determined that the optimum block generation speed is 12 seconds per block. A single leader within the temtum ecosystem will be responsible for five blocks, each of which are cryptographically linked to other blocks.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5">
                      <img src={undraw_security_o890} className="oppimg mt-5" alt=""/>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <a className="carousel-control-prev" href="#platform-wide" data-slide="prev">
            <span className="carousel-control-prev-icon"/>
          </a>
          <a className="carousel-control-next" href="#platform-wide" data-slide="next">
            <span className="carousel-control-next-icon"/>
          </a>
        </div>
      </section>
      <section id="section6" className="bg-white skipScroll">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 ">
              <div className="">
                <div className="">
                  <div className="boxedblueXX mb-4">
                    <h6 className="upper line text-grey mb-4">Technical</h6>
                    <h1 className="display-4 font-weight-bold mb-4 text-dark-blue">
                      Read The temtum White Paper
                    </h1>
                    <p className="leadX">
                      Our whitepaper is now available for review, with details on our Temporal blockchain, temtum network and currency, cryptography, technology integrations, team, road map and competition.
                    </p>
                    <Link to="/whitepaper" className="btn btn-outline-secondary">
                      Read White Paper
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 ">
              <div className="">
                <div className="">
                  <div className="boxedblueXX mb-4">
                    <h6 className="upper line blue text-grey mb-4">
                      Research
                    </h6>
                    <h1 className="display-4 font-weight-bold mb-4 text-dark-blue">
                      Read Our Research Papers
                    </h1>
                    <p className="leadX">
                      We've spent the past 5 years researching Blockchain and boast the only two Blockchain PHD's as part of our founding team. Read our research here.
                    </p>
                    <Link to="/research" className="btn btn-outline-primary">
                      View Research Papers
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>

  <div>
    <ModalProducts
      key={'Mobile'}
      id="Mobile"
      img={undraw_building_blocks_n0nc}
      title="Mobile Applications"
      description={
        <React.Fragment>
          <p>We’ve developed our temtum wallet into ios and Android applications to allow for seamless use of TEM across all devices. This allows for your TEM to be used from anywhere and isn’t limited to a single device or location like many cryptocurrency wallets.</p>
        </React.Fragment>
      } />

    <ModalProducts
      key="Keyboard"
      id="Keyboard"
      img={undraw_security_o890}
      title="Keyboard Payments"
      description={
        <React.Fragment>
          <p>
            This unique smartphone keyboard allows the user to access the benefits of the temtum application in a seamless way and can be used from any mobile messaging application, including Facebook, Whatsapp and Telegram, without the need for specialist currencies per application. It means that users can simply click on a custom temtum button and, without opening the temtum app, temtum coins (TEM) can be sent from one account to another.
          </p>
          <p>
            The keyboard is installed as a customised keyboard service as part of the temtum application. This enables sharing of the core functionality and the stored user credentials using the most secure standards for mobile apps. The user can access the temtum transfer functionality very simply, from any application.
          </p>
          <p>
            Both these features – the temtum application and the temtum keyboard – use the same temtum API capabilities and apply the same security standards. This design ensures data integrity between transactions either for the temtum mobile application and the temtum keyboard.
          </p>
        </React.Fragment>
      } />

    <ModalProducts
      key="Wallet"
      id="Wallet"
      img={undraw_security_o890}
      title="Web Wallet"
      description={
        <React.Fragment>
          <p>
            The temtum wallet works in the same way as a bank account in two fundamental ways – it is both a repository for currencies and a mechanism for completing transactions. Each wallet uses temtum technology to make transactions with other wallets on the network. But it is also different to a conventional bank account in other ways, such as:
          </p>
          <ul>
            <li>The wallet can be identified with only a username and/or wallet-address.</li>
            <li>Transaction speed: transactions will be confirmed within 12 seconds instead of waiting for days.</li>
            <li>Every transaction is confirmed by a decentralized network.</li>
            <li>temtum transactions have been tested using the web application, mobile applications, drone, watch, raspberry Pi and even a car.</li>
          </ul>
          <p>
            The temtum wallet is a cryptocurrency wallet where users can manage their TEM. We have combined all the necessary elements including security, simplicity, and easy access. The temtum website has full details of all the applications and examples of how to use the features of each application.
          </p>
        </React.Fragment>
      } />

    <ModalProducts
      key="Sms"
      id="Sms"
      img={undraw_security_o890}
      title="Transfers"
      description={
        <React.Fragment>
          <p>
            The SMS system does not require users to download an app, and there are no requirements for accounts or passwords. If the user has a device that can send an SMS text message, then they are able to use temtum. Similarly, the recipient needs neither an app nor a wallet to receive funds. This makes it suitable for non-smartphones and is targeted at  countries with a high penetration rate of such devices, the use of which is driven by unpredictable electrical power and intermittent, or less widely available, high speed internet.
          </p>
          <p>
            The SMS system allows for instant transfers. Transactions are immediately settled directly on the Temporal Blockchain and funds are never held by a third party.
          </p>
        </React.Fragment>
      } />
  </div>


</Layout> )

export default Platform;
